





















import { Vue, Component, Prop } from "vue-property-decorator";
import { MobileConfirmedOrderProperties } from "./MobileConfirmedOrderAView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { MobileDepositDialogProperties } from "./MobileDepositDialogView.vue";
import ProfileService from "@/services/ProfileService";
import ExceptionUtil from "@/utilities/ExceptionUtil";

@Component({
    data: () => ({
        walletBalance: 0,
    }),
})
export default class MobileWalletView extends Vue {
    @Prop() private properties: MobileConfirmedOrderProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private depositDialog: MobileDepositDialogProperties;
    private profileService = new ProfileService();

    public created() {
        this.load();
        this.properties.events.subscribe("load-wallet", this.load);
    }

    public destroyed() {
        this.properties.events.remove("load-wallet", this.load);
    }

    public async load() {
        try {
            const r = await this.profileService.walletBalance();
            this.$data.walletBalance = r.data.toFixed(2);
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public deposit() {
        this.depositDialog.visible = true;
    }
}
